
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'

import collectionImgJpg_1 from 'assets/images/collections_1.jpg'
import collectionImgJpg_2 from 'assets/images/collections_2.jpg'
import collectionImgJpg_3 from 'assets/images/collections_3.jpg'
import collectionImgJpg_4 from 'assets/images/collections_4.jpg'
import collectionImgJpg_5 from 'assets/images/collections_5.jpg'
import collectionImgJpg_6 from 'assets/images/collections_6.jpg'
import collectionImgJpg_7 from 'assets/images/collections_7.jpg'
import collectionImgJpg_8 from 'assets/images/collections_8.jpg'
import collectionImgJpg_9 from 'assets/images/collections_9.jpg'

import collectionImgWebp_1 from 'assets/images/collections_1.webp'
import collectionImgWebp_2 from 'assets/images/collections_2.webp'
import collectionImgWebp_3 from 'assets/images/collections_3.webp'
import collectionImgWebp_4 from 'assets/images/collections_4.webp'
import collectionImgWebp_5 from 'assets/images/collections_5.webp'
import collectionImgWebp_6 from 'assets/images/collections_6.webp'
import collectionImgWebp_7 from 'assets/images/collections_7.webp'
import collectionImgWebp_8 from 'assets/images/collections_8.webp'
import collectionImgWebp_9 from 'assets/images/collections_9.webp'

let collectionImages = {
  classic: collectionImgWebp_1,
  laconic: collectionImgWebp_2,
  party: collectionImgWebp_3,
  romantic: collectionImgWebp_4,
  luxurious: collectionImgWebp_5,
  kids: collectionImgWebp_6,
  bright: collectionImgWebp_7,
  birthday: collectionImgWebp_8,
  wedding: collectionImgWebp_9,
}

if (!isWebpSupported()) {
  collectionImages = {
    classic: collectionImgJpg_1,
    laconic: collectionImgJpg_2,
    party: collectionImgJpg_3,
    romantic: collectionImgJpg_4,
    luxurious: collectionImgJpg_5,
    kids: collectionImgJpg_6,
    bright: collectionImgJpg_7,
    birthday: collectionImgJpg_8,
    wedding: collectionImgJpg_9,
  }
}

const COLLECTION_LIST = [
  {
    id: '1',
    title: 'Торты',
    description: 'На День Рождения',
    src: collectionImages.birthday,
    isWide: false,
    isShow: true,
    direct: 'column',
    images: [
      { src: '/collections/birthday/birthday_1.jpg', title: '' },
      { src: '/collections/birthday/birthday_2.jpg', title: '' },
      { src: '/collections/birthday/birthday_3.jpg', title: '' },
      { src: '/collections/birthday/birthday_4.jpg', title: '' },
      { src: '/collections/birthday/birthday_5.jpg', title: '' },
      { src: '/collections/birthday/birthday_6.jpg', title: '' },
      { src: '/collections/birthday/birthday_7.jpg', title: '' },
      { src: '/collections/birthday/birthday_8.jpg', title: '' },
      { src: '/collections/birthday/birthday_9.jpg', title: '' },
      { src: '/collections/birthday/birthday_10.jpg', title: '' },
      { src: '/collections/birthday/birthday_11.jpg', title: '' },
      { src: '/collections/birthday/birthday_12.jpg', title: '' },
      { src: '/collections/birthday/birthday_13.jpg', title: '' },
      { src: '/collections/birthday/birthday_14.jpg', title: '' },
      { src: '/collections/birthday/birthday_15.jpg', title: '' },
      { src: '/collections/birthday/birthday_16.jpg', title: '' },
      { src: '/collections/birthday/birthday_17.jpg', title: '' },
      { src: '/collections/birthday/birthday_18.jpg', title: '' },
      { src: '/collections/birthday/birthday_19.jpg', title: '' },
      { src: '/collections/birthday/birthday_20.jpg', title: '' },
      { src: '/collections/birthday/birthday_21.jpg', title: '' },
      { src: '/collections/birthday/birthday_22.jpg', title: '' },
      { src: '/collections/birthday/birthday_23.jpg', title: '' },
      { src: '/collections/birthday/birthday_24.jpg', title: '' },
      { src: '/collections/birthday/birthday_25.jpg', title: '' },
      { src: '/collections/birthday/birthday_26.jpg', title: '' },
      { src: '/collections/birthday/birthday_27.jpg', title: '' },
      { src: '/collections/birthday/birthday_28.jpg', title: '' },
      { src: '/collections/birthday/birthday_29.jpg', title: '' },
      { src: '/collections/birthday/birthday_30.jpg', title: '' },
      { src: '/collections/birthday/birthday_31.jpg', title: '' },
      { src: '/collections/birthday/birthday_32.jpg', title: '' },
      { src: '/collections/birthday/birthday_33.jpg', title: '' },
      { src: '/collections/birthday/birthday_34.jpg', title: '' },
      { src: '/collections/birthday/birthday_35.jpg', title: '' },
      { src: '/collections/birthday/birthday_36.jpg', title: '' },
      { src: '/collections/birthday/birthday_37.jpg', title: '' },
      { src: '/collections/birthday/birthday_38.jpg', title: '' },
      { src: '/collections/birthday/birthday_39.jpg', title: '' },
      { src: '/collections/birthday/birthday_40.jpg', title: '' },
    ],
  },
  {
    id: '2',
    title: 'Торты',
    description: 'Праздники',
    src: collectionImages.party,
    isWide: false,
    isShow: true,
    direct: 'column',
    images: [
      { src: '/collections/party/party_1.jpg', title: '' },
      { src: '/collections/party/party_2.jpg', title: '' },
      { src: '/collections/party/party_3.jpg', title: '' },
      { src: '/collections/party/party_4.jpg', title: '' },
      { src: '/collections/party/party_5.jpg', title: '' },
      { src: '/collections/party/party_6.jpg', title: '' },
      { src: '/collections/party/party_7.jpg', title: '' },
      { src: '/collections/party/party_8.jpg', title: '' },
      { src: '/collections/party/party_9.jpg', title: '' },
      { src: '/collections/party/party_10.jpg', title: '' },
      { src: '/collections/party/party_11.jpg', title: '' },
      { src: '/collections/party/party_12.jpg', title: '' },
      { src: '/collections/party/party_13.jpg', title: '' },
      { src: '/collections/party/party_14.jpg', title: '' },
    ],
  },
  {
    id: '3',
    title: 'Торты',
    description: 'Для детей',
    src: collectionImages.kids,
    isWide: false,
    isShow: true,
    direct: 'column',
    images: [
      { src: '/collections/for_kids/for_kids_1.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_2.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_3.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_4.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_5.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_6.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_7.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_8.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_9.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_10.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_11.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_12.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_13.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_14.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_15.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_16.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_17.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_18.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_19.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_20.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_21.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_22.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_23.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_24.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_25.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_26.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_27.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_28.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_29.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_30.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_31.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_32.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_33.jpg', title: '' },
      { src: '/collections/for_kids/for_kids_34.jpg', title: '' },
    ],
  },
  {
    id: '4',
    title: 'Торты',
    description: 'Свадебные',
    src: collectionImages.wedding,
    isWide: false,
    isShow: true,
    direct: 'column',
    images: [
      { src: '/collections/wedding/wedding_1.jpg', title: '' },
      { src: '/collections/wedding/wedding_2.jpg', title: '' },
      { src: '/collections/wedding/wedding_3.jpg', title: '' },
      { src: '/collections/wedding/wedding_4.jpg', title: '' },
      { src: '/collections/wedding/wedding_5.jpg', title: '' },
      { src: '/collections/wedding/wedding_6.jpg', title: '' },
      { src: '/collections/wedding/wedding_7.jpg', title: '' },
      { src: '/collections/wedding/wedding_8.jpg', title: '' },
      { src: '/collections/wedding/wedding_9.jpg', title: '' },
      { src: '/collections/wedding/wedding_10.jpg', title: '' },
      { src: '/collections/wedding/wedding_11.jpg', title: '' },
      { src: '/collections/wedding/wedding_12.jpg', title: '' },
      { src: '/collections/wedding/wedding_13.jpg', title: '' },
      { src: '/collections/wedding/wedding_14.jpg', title: '' },
      { src: '/collections/wedding/wedding_15.jpg', title: '' },
      { src: '/collections/wedding/wedding_16.jpg', title: '' },
      { src: '/collections/wedding/wedding_17.jpg', title: '' },
      { src: '/collections/wedding/wedding_18.jpg', title: '' },
      { src: '/collections/wedding/wedding_19.jpg', title: '' },
      { src: '/collections/wedding/wedding_20.jpg', title: '' },
      { src: '/collections/wedding/wedding_21.jpg', title: '' },
    ],
  },
  {
    id: '5',
    title: 'Торты',
    description: 'Лаконичные',
    src: collectionImages.laconic,
    isWide: false,
    isShow: false,
    direct: 'column',
    images: [
      { src: '/collections/laconic/laconic_1.jpg', title: '' },
      { src: '/collections/laconic/laconic_2.jpg', title: '' },
      { src: '/collections/laconic/laconic_3.jpg', title: '' },
      { src: '/collections/laconic/laconic_4.jpg', title: '' },
      { src: '/collections/laconic/laconic_5.jpg', title: '' },
      { src: '/collections/laconic/laconic_6.jpg', title: '' },
      { src: '/collections/laconic/laconic_7.jpg', title: '' },
      { src: '/collections/laconic/laconic_8.jpg', title: '' },
      { src: '/collections/laconic/laconic_9.jpg', title: '' },
      { src: '/collections/laconic/laconic_10.jpg', title: '' },
      { src: '/collections/laconic/laconic_11.jpg', title: '' },
      { src: '/collections/laconic/laconic_12.jpg', title: '' },
      { src: '/collections/laconic/laconic_13.jpg', title: '' },
      { src: '/collections/laconic/laconic_14.jpg', title: '' },
      { src: '/collections/laconic/laconic_15.jpg', title: '' },
      { src: '/collections/laconic/laconic_16.jpg', title: '' },
      { src: '/collections/laconic/laconic_17.jpg', title: '' },
      { src: '/collections/laconic/laconic_18.jpg', title: '' },
      { src: '/collections/laconic/laconic_19.jpg', title: '' },
      { src: '/collections/laconic/laconic_21.jpg', title: '' },
      { src: '/collections/laconic/laconic_22.jpg', title: '' },
      { src: '/collections/laconic/laconic_23.jpg', title: '' },
      { src: '/collections/laconic/laconic_24.jpg', title: '' },
      { src: '/collections/laconic/laconic_25.jpg', title: '' },
      { src: '/collections/laconic/laconic_26.jpg', title: '' },
      { src: '/collections/laconic/laconic_27.jpg', title: '' },
      { src: '/collections/laconic/laconic_28.jpg', title: '' },
      { src: '/collections/laconic/laconic_29.jpg', title: '' },
      { src: '/collections/laconic/laconic_30.jpg', title: '' },
    ],
  },
  {
    id: '6',
    title: 'Торты',
    description: 'Романтичные',
    src: collectionImages.romantic,
    isWide: false,
    isShow: false,
    direct: 'column',
    images: [
      { src: '/collections/romantic/romantic_2.jpg', title: '' },
      { src: '/collections/romantic/romantic_5.jpg', title: '' },
      { src: '/collections/romantic/romantic_6.jpg', title: '' },
      { src: '/collections/romantic/romantic_7.jpg', title: '' },
      { src: '/collections/romantic/romantic_8.jpg', title: '' },
      { src: '/collections/romantic/romantic_11.jpg', title: '' },
      { src: '/collections/romantic/romantic_12.jpg', title: '' },
      { src: '/collections/romantic/romantic_13.jpg', title: '' },
      { src: '/collections/romantic/romantic_14.jpg', title: '' },
    ],
  },
  {
    id: '7',
    title: 'Торты',
    description: 'Яркие и дерзкие',
    src: collectionImages.bright,
    isWide: false,
    isShow: false,
    direct: 'column',
    images: [
      { src: '/collections/bright/bright_1.jpg', title: '' },
      { src: '/collections/bright/bright_2.jpg', title: '' },
      { src: '/collections/bright/bright_3.jpg', title: '' },
      { src: '/collections/bright/bright_5.jpg', title: '' },
      { src: '/collections/bright/bright_6.jpg', title: '' },
      { src: '/collections/bright/bright_7.jpg', title: '' },
      { src: '/collections/bright/bright_8.jpg', title: '' },
      { src: '/collections/bright/bright_10.jpg', title: '' },
      { src: '/collections/bright/bright_11.jpg', title: '' },
      { src: '/collections/bright/bright_12.jpg', title: '' },
      { src: '/collections/bright/bright_13.jpg', title: '' },
      { src: '/collections/bright/bright_14.jpg', title: '' },
    ],
  },
  {
    id: '8',
    title: 'Торты',
    description: 'Классика',
    src: collectionImages.classic,
    isWide: false,
    isShow: false,
    direct: 'row',
    images: [
      // { src: '/collections/classic/classic_1.jpg', title: 'Кокосовое пирожное' },
      { src: '/collections/classic/classic_2.jpg', title: 'Боншанс красный' },
      { src: '/collections/classic/classic_3.jpg', title: 'Боншанс синий' },
      { src: '/collections/classic/classic_4.jpg', title: 'Рейк торт' },
      { src: '/collections/classic/classic_5.jpg', title: 'Дос-потатос' },
      {
        src: '/collections/classic/classic_8.jpg',
        title: 'Торт «Игрушка» для девочки',
      },
      {
        src: '/collections/classic/classic_9.jpg',
        title: 'Торт «Игрушка» для мальчика',
      },
      { src: '/collections/classic/classic_7.jpg', title: 'Сметанник' },
      {
        src: '/collections/classic/classic_12.jpg',
        title: 'Сметанник с ягодами',
      },
      { src: '/collections/classic/classic_10.jpg', title: 'Французский торт' },
      { src: '/collections/classic/classic_11.jpg', title: 'Шато' },
      // { src: '/collections/classic/classic_13.jpg', title: 'Птичка и молоко' },
      { src: '/collections/classic/classic_14.jpg', title: 'Кето-чизкейк' },
    ],
  },
];

export default COLLECTION_LIST
